export function selecionarObjetoPadrao(lista) {
  return lista.length == 1 ? lista[0] : null
}

export function substituirValores(obj) {
  for (const key in obj) {
    obj[key] == null || obj[key] == '' || obj[key] == undefined ? obj[key] = 'Não Consta': obj[key]
    obj[key] == 'N' || obj[key] == 'false'? obj[key] = 'Não': obj[key]
    obj[key] == 'S' || obj[key] == 'true'? obj[key] = 'Sim': obj[key]
    
  }
  return obj
}
