<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Dados Pessoais (Diretas)</template>
        <template #content>
          <div v-if="matriculasList.length > 1" class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <Dropdown
                v-model="matricula"
                :options="matriculasList"
                data-key="id"
                placeholder="Matrícula"
                style="width: 100%">
                <template #value="slotProps">
                  <div v-if="slotProps.value">
                    <span>
                      {{ slotProps.value.matricula }} -
                      {{ slotProps.value.entidadeNome }}
                    </span>
                  </div>
                </template>
                <template #option="slotProps">
                  <span class="card-desktop">
                    {{ slotProps.option.matricula }} -
                    {{ slotProps.option.entidadeNome }}
                  </span>
                  <span class="card-mobile">
                    {{ slotProps.option.matricula }} -
                    {{ slotProps.option.entidadeNome | limitadorString }}
                  </span>
                </template>
              </Dropdown>
            </div>
          </div>

          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.nome"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Nome Completo</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.conjuge"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Cônjuge</label>
              </span>
            </div>
          </div>

          <div class="p-fluid grid mb-1">
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.dataNascimento | formatarData"
                  class="input-text"
                  disabled />
                <label for="cpf">Data de Nascimento</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="sexo"
                  type="text"
                  :value="matricula.sexo"
                  class="input-text"
                  disabled />
                <label for="sexo">Sexo</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="raca"
                  v-model="matricula.raca"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="raca">Raça</label>
              </span>
            </div>

            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nacionalidade"
                  v-model="matricula.nacionalidade"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nacionalidade">Nacionalidade</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="naturalidade"
                  type="text"
                  :value="
                    matricula.naturalidade + ' - ' + matricula.ufNaturalidade
                  "
                  class="input-text"
                  disabled />
                <label for="naturalidade">Naturalidade</label>
              </span>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.mae"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Nome da Mãe</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.pai"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Nome do Pai</label>
              </span>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-6 md:col-2">
              <div class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.matricula"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Matricula</label>
              </div>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="estadoCivil"
                  v-model="matricula.estadoCivil"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="estadoCivil">Estado Civil</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <Card>
        <template #title>Endereço</template>
        <template #content>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.cep"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">CEP</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.endereco"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Logradouro</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.numero"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Número</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.complemento"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Complemento</label>
              </span>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.cidade + ' - ' + matricula.uf"
                  class="input-text"
                  disabled />
                <label for="nome">Cidade</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="bairro"
                  type="text"
                  :value="matricula.bairro"
                  class="input-text"
                  disabled />
                <label for="bairro">Bairro</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <Card>
        <template #title>Contato</template>
        <template #content>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="'(' + matricula.ddd + ') ' + matricula.telefone"
                  class="input-text"
                  disabled />
                <label for="cpf">Telefone</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.ramal"
                  class="input-text"
                  disabled />
                <label for="cpf">Ramal</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.email"
                  class="input-text"
                  disabled />
                <label for="cpf">E-Mail</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <Card>
        <template #title>Formações</template>
        <template #content>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.formacao"
                  class="input-text"
                  disabled />
                <label for="cpf">Formação 1</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.formacao2"
                  class="input-text"
                  disabled />
                <label for="cpf">Formação 2</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.formacao3"
                  class="input-text"
                  disabled />
                <label for="cpf">Formação 3</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <Card>
        <template #title> Documentos </template>
        <template #content>
          <div class="p-fluid grid mb-1" style="margin-left: 1px">
            <div class="field col-12 md:col-6">
              <div class="p-fluid grid mb-1">
                <div class="field col-6 md:col-4">
                  <div class="p-float-label">
                    <InputText
                      id="nome"
                      v-model="matricula.cpf"
                      type="text"
                      class="input-text"
                      disabled />
                    <label for="cpf">CPF</label>
                  </div>
                </div>
              </div>
              <div class="p-fluid grid mb-1">
                <div class="field col-6 md:col-4">
                  <div class="p-float-label">
                    <InputText
                      id="nome"
                      type="text"
                      :value="matricula.identidade"
                      class="input-text"
                      disabled />
                    <label for="cpf">Identidade</label>
                  </div>
                </div>
                <div class="field col-6 md:col-5">
                  <div class="p-float-label">
                    <InputText
                      id="nome"
                      type="text"
                      :value="
                        matricula.identidadeOrgao +
                        ' - ' +
                        matricula.identidadeUf
                      "
                      class="input-text"
                      disabled />
                    <label for="cpf">Orgão Emissor</label>
                  </div>
                </div>
                <div class="field col-6 md:col-3">
                  <div class="p-float-label">
                    <InputText
                      id="nome"
                      type="text"
                      :value="matricula.identidadeEmissao | formatarData"
                      class="input-text"
                      disabled />
                    <label for="cpf">Data de Emissão</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <div class="p-fluid grid mb-1">
                <div class="field col-12 md:col-3">
                  <div class="p-float-label">
                    <InputText
                      id="nome"
                      v-model="matricula.titulo"
                      type="text"
                      class="input-text"
                      disabled />
                    <label for="cpf">Título de Eleitor</label>
                  </div>
                </div>
                <div class="field col-6 md:col-3">
                  <div class="p-float-label">
                    <InputText
                      id="nome"
                      v-model="matricula.tituloZona"
                      type="text"
                      class="input-text"
                      disabled />
                    <label for="cpf">Zona</label>
                  </div>
                </div>
                <div class="field col-6 md:col-3">
                  <div class="p-float-label">
                    <InputText
                      id="nome"
                      v-model="matricula.tituloSecao"
                      type="text"
                      class="input-text"
                      disabled />
                    <label for="cpf">Seção</label>
                  </div>
                </div>
              </div>
              <div class="p-fluid grid mb-1">
                <div class="field col-6 md:col-5">
                  <span class="p-float-label">
                    <InputText
                      id="carteiraTrabalho"
                      type="text"
                      :value="matricula.carteiraTrabalho"
                      class="input-text"
                      disabled />

                    <label for="carteiraTrabalho">Carteira de Trabalho</label>
                  </span>
                </div>
                <div class="field col-6 md:col-5">
                  <span class="p-float-label">
                    <InputText
                      id="carteiraTrabalhoSerial"
                      type="text"
                      :value="matricula.carteiraTrabalhoSerial"
                      class="input-text"
                      disabled />

                    <label for="carteiraTrabalhoSerial">Serial</label>
                  </span>
                </div>
                <div class="field col-6 md:col-2">
                  <span class="p-float-label">
                    <InputText
                      id="carteiraTrabalhoUf"
                      type="text"
                      value="PB"
                      class="input-text"
                      disabled />
                    <label for="carteiraTrabalhoUf">UF</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div v-if="verificarDocumentacaoMilitar()" class="col-12">
      <Card>
        <template #title> Documentação Militar </template>
        <template #content>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="documentoMilitar"
                  type="text"
                  :value="
                    matricula.documentoMilitar +
                    ' - ' +
                    matricula.documentoMilitarUf
                  "
                  class="input-text"
                  disabled />
                <label for="documentoMilitar">Documento Militar</label>
              </span>
            </div>
            <div class="field col-12 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="documentoMilitarRm"
                  type="text"
                  :value="matricula.documentoMilitarRm"
                  class="input-text"
                  disabled />
                <label for="documentoMilitarRm">RM</label>
              </span>
            </div>
            <div class="field col-12 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="documentoMilitarSerie"
                  type="text"
                  :value="matricula.documentoMilitarSerie"
                  class="input-text"
                  disabled />
                <label for="documentoMilitarSerie">Serie</label>
              </span>
            </div>
            <div class="field col-12 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="documentoMilitarTipo"
                  type="text"
                  :value="matricula.documentoMilitarTipo"
                  class="input-text"
                  disabled />
                <label for="documentoMilitarTipo">Tipo</label>
              </span>
            </div>
            <div class="field col-12 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="documentoMilitarCsm"
                  type="text"
                  :value="matricula.documentoMilitarCsm"
                  class="input-text"
                  disabled />
                <label for="documentoMilitarCsm">CSM</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import MatriculaService from '@/service/MatriculaService.js'
import PortalServidorService from '@/service/PortalServidorService'
import { substituirValores } from '@/utils/SelecionarObjetoPadrao'

export default {
  data() {
    return {
      matriculasList: [],
      matricula: {},
      loading: false,
      servidor: {},
      matriculaValue: {},
    }
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
    this.portalServidorService = new PortalServidorService(this.$http)
  },

  mounted() {
    this.carregarDados()
  },

  methods: {
    carregarDados() {
      this.loading = true
      this.matriculaService
        .getMatriculasUsuarioLogado()
        .then((res) => {
          this.getDadosPessoaisByCpf(res[0].cpf)
          this.limpar()
        })
        .catch((err) => {
          const mensagem = err.response
            ? err.response.data.message
            : 'Erro de conexão com a aplicação.'
          this.limpar()
          this.$toast.add({
            severity: 'error',
            summary: mensagem,
            life: 10000,
          })
          this.$router.push('/logout')
        })
    },

    verificarDocumentacaoMilitar() {
      return [
        this.matricula.documentoMilitar,
        this.matricula.documentoMilitarCsm,
        this.matricula.documentoMilitarSerie,
        this.matricula.documentoMilitarRm,
        this.matricula.documentoMilitarTipo,
        this.matricula.documentoMilitarUf,
      ].some((item) => item != 'Não Consta')
    },
    getDadosPessoaisByCpf(cpf) {
      this.portalServidorService.getDadosPessoaisByCpf(cpf).then((res) => {
        this.matriculasList = res
        for (const idx in this.matriculasList) {
          this.matriculasList[idx].id = parseInt(idx)
          this.matriculasList[idx] = substituirValores(this.matriculasList[idx])
        }
        this.matricula = this.matriculasList[0]
      })
    },
    limpar() {
      this.loading = false
    },
  },
}
</script>

<style>
.input-text {
  width: 100%;
  color: black;
}
</style>
